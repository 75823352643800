import React, { useEffect } from 'react';
import "../Assets/styles/Contact.css"
import BreadCrumbShape1 from "../Assets/Resources/contact-breadcrumb.png"
import BreadCrumbShape2 from "../Assets/Resources/contact-breadcrumb-light.png"
import ContactArrow from "../Assets/Resources/contact-bread-arrow.svg"
import WhoWeAreChecked from "../Assets/Resources/who-we-are-checkedbox.png"
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import AllPageLoader from '../Components/AllPageLoader';
import AOS from "aos";
import "aos/dist/aos.css";
const Contact = () => {



    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const SplitTypes = document.querySelectorAll('.text-reveal-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                scaleY: 0,
                y: -20,
                transformOrigin: 'top',
                stagger: 0.05
            });
        });
    }, []);

    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.ban-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);



    useEffect(() => {
        const SplitTypes = document.querySelectorAll('.mini-title-animation');
        SplitTypes.forEach((char) => {
            const text = new SplitType(char, { types: 'chars' });
            gsap.from(text.chars, {
                scrollTrigger: {
                    trigger: char,
                    start: 'top 80%',
                    end: 'top 20%',
                    scrub: true,
                    markers: false
                },
                opacity: 0.2,
                stagger: 0.08,
                scrub: true,
            });
        });
    }, []);


    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const elements = document.querySelectorAll('.para-animation');
        elements.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 20 }, // Start with opacity 0 and slightly below the position
                {
                    opacity: 1,        // End with opacity 1 (fully visible)
                    y: 0,              // Move to the original position
                    duration: 1,       // Adjust duration for smoother transition
                    ease: "power2.out", // Smooth easing
                    scrollTrigger: {
                        trigger: element,
                        start: "top 80%",
                        end: "top 20%",
                        scrub: true,    // One-time animation on scroll
                        markers: false,
                    },
                }
            );
        });
    }, []);





    return (
        <>
            <AllPageLoader />
            <section className='breadcrumb contact-breadcrumb'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="breadcrumb-title">
                            <h1 className='ban-title-animation'>Partner with us!</h1>
                            <h6 data-aos="fade-down" data-aos-duration="2000" data-aos-delay="300">For Progress and Possibility</h6>
                            <p data-aos="fade-down" data-aos-duration="2000" data-aos-delay="1000">Connect with Prophecy Technologies to explore innovative solutions tailored to your business needs. Our global team is ready to collaborate and drive your digital transformation.</p>
                        </div>
                        <div className="shape1">
                            <img src={BreadCrumbShape1} alt="" />
                        </div>
                        <div className="shape2" data-aos="fade-down" data-aos-duration="3000" data-aos-delay="500">
                            <img src={BreadCrumbShape2} alt="" />
                        </div>
                        <a href="#contactDownArrow">
                            <div className="downarrow">
                                <img src={ContactArrow} alt="" />
                            </div>
                        </a>
                    </div>
                </div>
            </section>


            <div className="gradient-bg contact-gradient-bg">
                <section className='contact-form-section' >
                    <div className="container">
                        <div className="row" >
                            <div className="sec-title large-title " >
                                <h1 className='mini-title-animation'>Enter your info <br />To get started</h1>
                                <h6 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">with Prophecy Technologies</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="contact-form" data-aos="zoom-in" data-aos-duration="2000">
                                <h1>Contact Us Form</h1>
                                <form >
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>Your Name
                                                <input type="text" placeholder='Your Name' />
                                            </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Enter Email
                                                <input type="text" placeholder='example@gmail.com' />
                                            </label>
                                        </div>
                                        <div className="col-lg-12">
                                            <label>Your Message
                                                <textarea type="text" placeholder='Example' />
                                            </label>
                                        </div>
                                        <div className="col-lg-12">
                                            <label className='checkbox-form'>
                                                <input type="checkbox" className="custom-checkbox" />
                                                <span className="checkmark"></span>
                                                I agree to the privacy policy
                                            </label>
                                        </div>
                                        <div className="form-submit-btn common-btn" data-aos="zoom-in" data-aos-duration="2000" >
                                            <button>Send Message</button>
                                        </div>
                                    </div>

                                </form>
                                <div className="checked-shape">
                                    <img src={WhoWeAreChecked} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='opportunity-section'>
                    <div className="container">
                        <div className="row">
                            <div className="sec-title large-title ">
                                <h6 data-aos="fade-down" data-aos-duration="2000">Prophecy Technologies :</h6>
                                <h1 className='mini-title-animation'>Looking for<br />Opportunity ?</h1>
                                <p data-aos="fade-up" data-aos-duration="2000">Prophecy Technologies is always on the lookout for talented individuals passionate about driving innovation. If you're searching for a career that pushes boundaries, send us your resume.</p>
                                <div className="common-btn" data-aos="zoom-in" data-aos-duration="2000">
                                    <button>Send Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="gradient-bg-radial-1"></div>
                <div className="gradient-bg-radial-2"></div>
                <section className='contact-location' id='contactDownArrow'>
                    <div className="container">
                        <div className="row">
                            <div className="sec-title large-title ">
                                <h1 className='mini-title-animation'>Wanna Know<br />Where We Are?</h1>
                                <h6 data-aos="fade-up" data-aos-duration="2000">Discover Our Office Locations</h6>
                            </div>
                        </div>
                    </div>
                    <div className="checked-shape">
                        <img src={WhoWeAreChecked} alt="" />
                    </div>
                </section>
            </div>


            <section className='branch-address' >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="branch-item" data-aos="zoom-in" data-aos-duration="2000">
                                <h6>USA California</h6>
                                <p>7545 Irvine Center Drive, Suite 200, Irvine, CA 92618
                                    United States</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="branch-item" data-aos="zoom-in" data-aos-duration="2000">
                                <h6>Canada Toronto</h6>
                                <p>405, The West Mall, Suite 910, Toronto, ON - M9C 5J5
                                    Canada</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="branch-item" data-aos="zoom-in" data-aos-duration="2000">
                                <h6>Chennai Tidel Park</h6>
                                <p>5th Floor - C Block, TIDEL Park, 4, Canal Bank Rd, Taramani, Chennai</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="branch-item" data-aos="zoom-in" data-aos-duration="2000">
                                <h6>Trichy</h6>
                                <p>Plot No 13 Bharathi Nagar, 7T Sangiliyandapuram, ,Tamil Nadu,Trichy</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='contact-cta'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="sec-title large-title ">
                            <h1 className='mini-title-animation'>Our Global <br />Presence</h1>
                            <h6 className='para-animation'>With offices across the USA, Canada, and India, we ensure that wherever you are, Prophecy Technologies is within reach. Collaborate with us from any corner of the globe as we tailor digital solutions that make a difference.</h6>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact